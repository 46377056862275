jQuery( document ).ready(function( $ ) {
    $("#menusys_moo > .haschild").append('<span class="menu-toggle"></span>'), $(".nav-toggle").click(function() {
        $(this).toggleClass("open"), $(".collapse").toggleClass("show")
    }), $(".menu-toggle").click(function() {
        event.preventDefault(), $(this).siblings("li.haschild ul").toggleClass("show")
    }), $(".menu-toggle_2").click(function() {
        event.preventDefault(), $(this).siblings("li.haschild ul").toggleClass("show")
    })
})

var dropCookie = true; 
var cookieName = 'cookies-mutuelle-pas-cher';
var cookieValue = 'active'; 
 
function createCookie(name,value,days) {
    if (days) {
        var date = new Date();
        date.setTime(date.getTime()+(days*24*60*60*1000)); 
        var expires = "; expires="+date.toGMTString(); 
    }
    else var expires = "";
    if(window.dropCookie) { 
        document.cookie = name+"="+value+expires+"; path=/"; 
    }
}
 
function checkCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}
jQuery(function($) {
window.onload = function(){
    if(checkCookie(window.cookieName) != window.cookieValue){
		$('#cookieAcceptBar').show(); 
        createCookie(window.cookieName,window.cookieValue, window.cookieDuration); 
    }
}
$('#cookieAcceptBarConfirm').click(function(){
      $('#cookieAcceptBar').hide();
    });
});